<template>
    <div class="row">
        <label for="subject" class="col-sm-3 col-form-label required-label">{{$t('employee.mail_subject')}}</label>
        <div class="col-sm-9">
            <input v-if="isReminder" type="text" id="subject" v-model="item.subject" />
            <input v-else type="text" id="subject" v-model="item.email_subject" />
        </div>
    </div>
    <div class="row">
        <label for="contents" class="col-sm-3 col-form-label required-label">{{$t('form.message')}}</label>
        <div class="col-sm-9">
            <textarea v-model="item.email_body" id="contents" rows="8"></textarea>
        </div>
    </div>
    <div class="row" v-if="!isReminder">
        <label for="date" class="col-sm-3 col-form-label required-label">{{$t('form.effective_date')}}</label>
        <div class="col-sm-9">
            <input type="date" id="date" v-model="item.date" />
        </div>
    </div>
</template>

<script>
    import employeeService from '@/services/EmployeeService';
    import contractService from '@/services/ContractService';
    import store from '@/store/user'
    export default {
        name: 'MailRequestForm',
        props: ['itemData'],
        data() {
            return {
                item: {
                    'email_body': '',
                    'email_subject': '',
                    'date': '',
                    //'id': null,
                    'company_id': 0
                },
                isReminder: false,
                submitted: false,
                loading: false,
                service: {},
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.item = val    
            },
        },
        methods: {
            validate(){
                if(this.isReminder){
                    if(!this.item.email_body || !this.item.subject || !this.item.email_to) return false;
                }
                else{
                    if(!this.item.email_body || !this.item.email_subject || !this.item.date) return false;
                }
                this.service.mail(this.item, this.item.id).then(response => {
                    this.$toast.success(this.$t('overview.email_sent'));
                    const el = document.getElementById('modal-close-button');
                    if(el) el.click();
                }).catch(error => {
                    this.toastError(error)
                    console.log('error', error)
                })
            },
            getData() {
                return this.item;
            },
            setData(data) {
                this.isReminder = data.isReminder;
                this.service = this.isReminder ? contractService : employeeService;
                if(this.isReminder){
                    this.item = {
                        'email_body':`Beste ${data.first_name} ${data.last_name},

Voordat we jouw salaris kunnen betalen hebben wij jouw persoonlijke gegevens nodig.
Via onderstaande knop kun je deze snel en gemakkelijk aanleveren. 
Wij gaan vertrouwelijk met de gegevens om en slaan deze alleen op in jouw personeelsdossier.
Let op: zolang jij geen gegevens hebt aangeleverd, kunnen wij jou niet verlonen.

Bedankt!`,
                        'subject': `Herinnering uitnodiging ${store.getters.getCurrentCompanyName}`,
                        'email_to': data.email,
                        'id': data.contract.id
                    };
                }
                else{
                    this.item = {
                        'email_body': `Beste medewerker van IBEO,\n\nHier volgt een bericht ter attentie van ${data.first_name} ${data.last_name} (${data.email}) van ${store.getters.getCurrentCompanyName}.\n\n`,
                        'email_subject': `${store.getters.getCurrentCompanyName}: wijziging via IBEO HR`,
                        'company_id': store.getters.getCurrentCompanyId,
                        'date': ''
                    };
                }
            },
            reset() {
                this.item = {
                    'email_body': '',
                    'email_subject': '',
                    //'id': null,
                    'company_id': 0,
                    'date': ''
                }
                this.loading = false
                this.invalid = {}
            }
        },
        mounted () {
            const el = document.getElementById('savebutton');
            if(el) el.innerHTML = this.$t('form.send');
        }
    }
</script>
