<template>

              
    <div v-if="currentCompany && !currentCompany.representative_email" class="alert alert-danger" role="alert" style="text-align: center;">
        Onvolledige bedrijfs informatie - <router-link to="/companies">Vul gegevens in hier</router-link>
    </div>
    <div class="w-100" id="employee-overview">
        <Overview 
            :moduleName="$t('overview.employee_header_2')"
            :moduleNamePlural="$t('overview.employee_header_2')"
            :sort="'first_name'"
            :sortOrder="-1"
            :itemService=itemService
            :itemFormRef=itemFormRef
            :showCopy="false"
            :filterGroups=filters
            ref="overview"
        >
            <template #columns>
                <Column field="first_name" :header="$t('overview.name')" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.first_name + ' ' + slotProps.data.last_name}}
                    </template>
                </Column>
                <Column field="email" :header="$t('form.email')" :sortable="true" style="width: 25%"></Column>
                <Column field="employee_status_id" :header="$t('overview.status')" :sortable="true" style="width: 25%">
                    <template #body="slotProps">
                        {{ getEmployeeStatus(slotProps.data) }}
                    </template>
                </Column>
            </template>

            <template #form>
                <MailRequestForm ref="ItemForm" :itemData="item" />
            </template>

            <template #modal-footer>
                <!--<button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;Volgende<span id="nextStep">2</span></button>-->
            </template>
        </Overview>
        <div class="footer-buttons mb-2">
            <button type="button" class="btn secondary-button">{{ $t('overview.goto', ['NMBRS']) }}&ensp;<i class="fa fa-arrow-up-right-from-square"></i></button>
            <router-link to="/new-employee" class="btn action-button"><i class="fa fa-user-plus"></i>&ensp;{{ $t('form.add', [$t('navigation.employees')]) }}</router-link>
        </div>
        <button id="inv_button" type="button" class="d-none" @click="index"></button>
    </div>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import employeeService from '@/services/EmployeeService';
import MailRequestForm from '@/components/forms/MailRequestForm.vue';
import companyService from '@/services/CompanyService'
import store from '@/store/user'

export default {
    name: 'Employees',
    components: {
        Overview,
        Column,
        MailRequestForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: employeeService,
            currentCompany: null,
            filters: [
                {
                    name : "Statussen",
                    field: "employee_status_id",
                    values: [
                        {id: 1, name: "In dienst"},
                        {id: 2, name: "Uit dienst"},
                        {id: 3, name: "In afwachting van gegevens"},
                        {id: 4, name: "Afwachting contract tekenen"}
                    ]
                },
                /*{
                    name : "Dienstverbanden",
                    field: "employment_type",
                    values: [
                        {id: "fulltime", name: "Fulltime"},
                        {id: "internship", name: "Stage"},
                        {id: "hourly", name: "Uurcontract"},
                    ]
                }*/
            ],
            emplymentType: [],
            employeeStatus: []
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
        },
        index(){            
            this.$refs.overview.getIndexEmployees();
        },
        getEmployeeStatus(data){
            return data.employee_status ? (data.employee_status.name[0].toUpperCase() + data.employee_status.name.substr(1)) : (data.employee_type == 'payroll' ? 'In dienst' : 'Uit dienst');
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        companyService.show(store.getters.getCurrentCompanyId).then(response => {
            this.currentCompany = response.data
        })
    }
}
</script>